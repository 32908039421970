import {useContext, useEffect, useState} from "react";
import "./Carousel.css";
import {AppContext} from "../../App";

function CarouselMobile(props) {
    const images = props.images;
    const details = props.details
    const numImages = images.length;

    const [index, setIndex] = useState(0);

    const {setCarouselDetailModalIsOpen, setCarouselDetailModalAttachments, setCarouselDetailModalId} = useContext(AppContext);

    async function transition(oldId, newId) {
        let oldImage = document.getElementById(getImageIdFromIndex(oldId));
        let newImage = document.getElementById(getImageIdFromIndex(newId));

        if (newImage && oldImage){
            newImage.classList.remove("hidden");
            newImage.classList.remove("end");
            newImage.classList.add("start");

            oldImage.classList.remove("start");
            oldImage.classList.add("end");
        }

        await new Promise(r => setTimeout(r, 1000));
    }

    function getImageIdFromIndex(id){
        const imagePrefix = "carousel_image-";

        return imagePrefix + id;
    }

    useEffect(() => {
        async function changeImage(i){
            i++;

            await transition(index,i % numImages);

            setIndex(i % numImages);

            return i;
        }

        if (numImages > 1){
            let i = index;
            const changeImg = setInterval(() => {
                i = changeImage(i);
            }, 6000);
            return () => {
                clearInterval(changeImg);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    function handleNext(){
        toImage(index === (numImages - 1) ? 0 : (index + 1));
    }

    function handlePrev(){
        toImage(index === 0 ? (numImages - 1) : (index - 1));
    }

    function handleTo(e){
        const imageId = parseInt(e.target.value);

        if(imageId !== index){
            toImage(imageId);
        }
    }

    async function toImage(id){
        await transition(index, id);

        setIndex(id);
    }

    function handleClick(){
        setCarouselDetailModalAttachments(images);
        setCarouselDetailModalId(index);
        setCarouselDetailModalIsOpen(true);
    }

    return (
        <div className={"h-[16rem] md:h-[36rem]"}>
            <div className="relative w-full h-full">
                <div className="relative overflow-hidden h-full">
                    {images.map((image, j) => {
                        return (
                        <div key={"carousel_image-" + j} id={"carousel_image-" + j} className={"w-full h-full absolute top-0" + (index !== j ? " hidden" : "")} >
                            <img src={image.url}
                                 className={"absolute md:object-fill h-full block -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 " + (details ? "object-contain" : "object-cover")}
                                 alt="..."
                                 onClick={handleClick}
                            />
                        </div>);
                    })}
                </div>
                {numImages > 1 ?
                <>
                <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
                    {images.map((image, j) => {
                        return (
                        <button type="button" className={"w-3 h-3 rounded-full bg-primary hover:bg-primary-light" + (index === j ? " bg-primary-light" : "")}
                                value={j} onClick={handleTo} key={"carousel-toimagebtn-"+j}></button>
                        );
                    })}
                </div>
                <button type="button"
                        onClick={handlePrev}
                        className="absolute top-0 md:-left-14 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
                    <span
                        className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10">
                        <svg aria-hidden="true" className="w-5 h-5 text-primary hover:text-primary-light sm:w-6 sm:h-6" fill="none"
                             stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                            strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                        <span className="sr-only">Previous</span>
                    </span>
                </button>
                <button type="button"
                        onClick={handleNext}
                        className="absolute top-0 right-0 md:-right-14 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
                    <span
                        className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10">
                        <svg aria-hidden="true" className="w-5 h-5 text-primary hover:text-primary-light sm:w-6 sm:h-6" fill="none"
                             stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path
                            strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                        <span className="sr-only">Next</span>
                    </span>
                </button>
                </>
                : ""}
            </div>
        </div>
    );
}

export default CarouselMobile;
