import React, {useContext, useState} from "react";
import { AppContext} from "../../App";

function CarouselDetailModal() {
    const {carouselDetailModalIsOpen, carouselDetailModalAttachments, carouselDetailModalId} = useContext(AppContext);

    const [index, setIndex] = useState(carouselDetailModalId);

    function handlePrev() {
        let id = index;

        if (id === 0){
            id = carouselDetailModalAttachments.length - 1;
        }
        else{
            id--;
        }

        setIndex(id);
    }

    function handleNext() {
        let id = index;

        if (id === carouselDetailModalAttachments.length - 1){
            id = 0;
        }
        else{
            id++;
        }

        setIndex(id);
    }

    if (!carouselDetailModalIsOpen){
        return (<div></div>);
    }

    return (
        <div id="carouselDetailModal" tabIndex="-1"
             className="fixed top-0 left-0 right-0 inset-0 m-auto w-full md:w-4/6 z-50 overflow-x-hidden h-fit md:h-fit md:max-h-[calc(100%-4rem)] overflow-y-auto">
            <div className="relative w-full max-h-[calc(100%-4rem)]">
                <img className="w-full max-h-[20rem] md:max-h-[calc(100%-4rem)] bg-white" src={carouselDetailModalAttachments[index].url} alt="" />
            </div>
            {carouselDetailModalAttachments.length > 1 ?
                <>
                    <button type="button"
                            onClick={handlePrev}
                            className="absolute top-0 md:left-0 z-30 flex items-center justify-center h-full md:h-full px-4 cursor-pointer group focus:outline-none">
                        <svg className="w-8 h-8 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7"/>
                        </svg>
                    </button>
                    <button type="button"
                            onClick={handleNext}
                            className="absolute top-0 right-0 md:right-0 z-30 flex items-center justify-center h-full md:h-full px-4 cursor-pointer group focus:outline-none">
                        <svg className="w-8 h-8 text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7"/>
                        </svg>
                    </button>
                </>
                : ""}
        </div>
    );
}

export default CarouselDetailModal;
