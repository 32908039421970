import ContactForm from "./ContactForm";
import React, {useContext, useEffect, useState} from "react";
import {API_ADDRESS, AppContext} from "../../App";

function ContactModal() {
    const {contactModalIsOpen, contactModalPropertyRef, setContactModalIsOpen} = useContext(AppContext);
    const [property, setProperty] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    function handleClose(){
        setContactModalIsOpen(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/properties/' + contactModalPropertyRef)
                .then(res => res.json())
                .then(json => {
                    setProperty(json.data);
                    setIsLoading(false);
                });
        };

        if (contactModalPropertyRef) {
            fetchData();
        }
    }, [contactModalPropertyRef]);

    if (!contactModalIsOpen){
        return (<div></div>);
    }

    return (
        <div id="contactModal" tabIndex="-1"
             className="fixed md:left-1/4 md:top-14 w-full max-h-screen md:w-1/2 z-50 p-4 overflow-x-hidden overflow-y-auto">
            <div className="relative w-full">
                <div className="relative bg-white shadow">
                    <div className="flex items-start justify-between p-4 border-b rounded-t">
                        <h3 className="text-2xl font-semibold text-primary">
                            Formulaire de contact
                        </h3>
                        <button type="button" onClick={handleClose}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="defaultModal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Fermer le formulaire</span>
                        </button>
                    </div>
                    <div className="p-6">
                        <div className="text-center">
                            <h3 className="text-md mb-4">Remplissez ce formulaire et nous vous répondrons dans les
                                plus brefs délais.</h3>
                        </div>
                        {!isLoading && contactModalPropertyRef ? (
                            <div className="flex justify-center">
                                <div className="text-center mb-4 border p-2 max-w-fit">
                                    <div className="text-2xl">
                                        {property.name}
                                    </div>
                                    <div className="text-sm">{property.subtitle}</div>
                                    <div className="flex justify-center mt-2">
                                        <div className="rounded-md text-sm bg-primary text-white py-1 px-3 w-fit">
                                            {property.price === 0 ?
                                                "Prix sur demande"
                                                :
                                                <>
                                                    CHF {property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}.–{property.type === "rent" ? "/mois" : ""}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ""}
                        <ContactForm propertyRef={contactModalPropertyRef} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactModal;
