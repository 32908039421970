import PromotionTableRow from "./PromotionTableRow";

function PromotionTable(props) {
    const promotion = props.promotion;

    let buildings = promotion.buildings;

    buildings.sort(function(a, b) {
        let refA = a.ref.split("-")[1];
        let refB = b.ref.split("-")[1];

        return refA.localeCompare(refB);
    });

    return (
        <table className="w-full text-sm text-left text-primary">
            <thead
                className="text-xs text-primary uppercase bg-gray-300">
            <tr>
                <th scope="col" className="hidden md:table-cell text-center px-6 py-3">
                    Lot N°
                </th>
                <th scope="col" className="text-center px-6 py-3">
                    Description
                </th>
                <th scope="col" className="hidden md:table-cell text-center px-6 py-3">
                    Étage
                </th>
                <th scope="col" className="table-cell text-center px-6 py-3">
                    Pièces
                </th>
                <th scope="col" className="hidden md:table-cell text-center px-6 py-3">
                    Surface
                </th>
                <th scope="col" className="text-center px-6 py-3">
                    Prix
                </th>
                <th scope="col" className="hidden md:table-cell text-center px-6 py-3">
                    Disponibilité
                </th>
            </tr>
            </thead>
            <tbody>
            {buildings.map((building, i) => (
                <PromotionTableRow key={i} promotionRef={promotion.ref} building={building} />
            ))}
            </tbody>
        </table>
    );
}

export default PromotionTable;
