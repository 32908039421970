import React from "react";
import {useParams} from "react-router-dom";

function Share() {
    const { ref } = useParams();

    window.location.href = "/propriete?ref=" + ref;

    return (
        <div>
        </div>
    );
}

export default Share;
