import {useContext, useState} from "react";
import {copySearchForm, SearchFormContext} from "./SearchForm";

function Dropdown(props) {
    const { searchForm, setSearchForm } = useContext(SearchFormContext);
    const type = props.type ? props.type : "select";
    const title = props.title;
    const name = props.name;
    const values = props.values;
    const [value, setValue] = useState(searchForm[name]);
    const [items, setItems] = useState(props.items);

    function updateSearchForm(field, value){
        let tempSearchForm = copySearchForm(searchForm);

        tempSearchForm[field] = value;

        setSearchForm(tempSearchForm);
    }

    function hideMenus() {
        const menus = document.getElementsByClassName("dropdown-menu");
        const svgs = document.getElementsByClassName("dropdown-svg");

        for(let menu of menus){
            if(!menu.classList.contains("hidden")){
                menu.classList.add("hidden");
            }
        }

        for(let svg of svgs){
            if(svg.classList.contains("rotate-0")){
                svg.classList.remove("rotate-0");
                svg.classList.add("-rotate-90");
            }
        }
    }

    function toggleMenu() {
        const menu = document.getElementById("dropdown-menu-"+name);
        const svg = document.getElementById("dropdown-svg-"+name);

        if(menu.classList.contains("hidden")){
            hideMenus();
            svg.classList.add("rotate-0");
            svg.classList.remove("-rotate-90");
            menu.classList.remove("hidden");
        }
        else{
            menu.classList.add("hidden");
            svg.classList.add("-rotate-90");
            svg.classList.remove("rotate-0");
        }
    }

    function filterList(e){
        let filteredList;

        if(e.target.value !== ""){
            filteredList = items.map((item) => {
                item.hide = !(item.name.toLowerCase().includes(e.target.value.toLowerCase()));
                return item;
            });
        }
        else{
            filteredList = items.map((item) => {
                item.hide = false;
                return item;
            });
        }

        setItems(filteredList);
    }

    function handleValue(e) {
        let tempValue;
        let itemId = e.target.value;

        if (type === "select"){
            tempValue = JSON.parse(JSON.stringify(value));
            const i = tempValue.findIndex(e => e === items[itemId][values]);

            if(i > -1){
                tempValue.splice(i, 1);
            }
            else{
                tempValue.push(items[itemId][values]);
            }
        }
        else{
            tempValue = items[itemId][values];
        }

        setValue(tempValue);

        updateSearchForm(e.target.name, tempValue);
    }

    function getItemName(){
        for (let item of items){
            if (value === item[values]){
                return item.name;
            }
        }

        return value;
    }

    return (
        <div className="flex justify-stretch flex-col relative h-full">
            <button onClick={toggleMenu}
                    className="group first-letter:uppercase h-full text-primary bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-light font-medium px-5 py-2.5 inline-flex justify-between items-center"
                    type="button">
                {type === "select" ?
                    value.length > 0 ? value.map((val) => {
                        let returnVal = null;
                        if (typeof val !== "string"){
                            returnVal = val.name
                        }
                        else{
                            for (let item of items) {
                                if (item[values] === val) {
                                    returnVal = item.name;
                                }
                            }
                        }
                        return returnVal;
                    }).join(", ") : title
                    :
                    value ? typeof value !== "string"  ? value.name : getItemName() : title
                }
                <svg id={"dropdown-svg-"+name} className="w-2.5 h-2.5 ml-2.5 -rotate-90 group-hover:rotate-0 transition dropdown-svg" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="m1 1 4 4 4-4"/>
                </svg>
            </button>

            <div className="z-30 hidden bg-white absolute top-full w-full dropdown-menu border border-primary" id={"dropdown-menu-"+name}>
                <div className="p-3">
                    <label className="sr-only">Rechercher</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="text" onChange={filterList}
                               className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-light focus:border-primary-light"
                               placeholder={"Rechercher " + title.toLowerCase()} />
                    </div>
                </div>
                <ul className="max-h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700">
                    {items ? items.map((item, index) => (
                        <li key={index} className={item.hide ? "hidden" : ""}>
                            <div className="flex items-center pl-2 rounded hover:bg-gray-100">
                                <input id={"dropdown-checkbox-item-"+item.name} name={name} type={type === "select" ? "checkbox" : "radio"} value={index} onChange={handleValue}
                                       className="w-4 h-4 text-primary-light bg-gray-100 border-gray-300 rounded"
                                       checked={value !== 0 ? typeof value === "string" ? item[values] === value : value.includes(item[values]) : false} />
                                <label htmlFor={"dropdown-checkbox-item-"+item.name} className="w-full py-2 ml-2 font-medium text-gray-900 rounded">
                                    {item.name}
                                </label>
                            </div>
                        </li>
                    )) : ""}
                </ul>
            </div>
        </div>
    );
}

export default Dropdown;
