import PropertyCard from "./PropertyCard";
import PropertySkeleton from "./PropertySkeleton";
import React from "react";


function PropertyList(props) {
    const properties = props.properties;
    const isLoading = props.isLoading;
    const promotionRef = props.promotionRef;

    const nSkeletons = 9;

    return (
        <div className="container mx-auto w-3/4 grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-6">
            {properties.length > 0 || !isLoading ?
                <>
                    {properties.map((property, index) => (
                        <PropertyCard
                            key={index}
                            property={property}
                            promotionRef={promotionRef}
                        />
                    ))}
                </>
                :
                <>
                    {Array(nSkeletons).fill("").map((value, index) => (
                        <PropertySkeleton key={index}/>
                    ))}
                </>
            }
        </div>
    );
}

export default PropertyList;
