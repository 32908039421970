import React, {useContext} from "react";
import {MIN_POS} from "../Navigation/NavBar";
import {AppContext} from "../../App";

function ContactModalButton(props) {
    const {setContactModalIsOpen, setContactModalPropertyRef} = useContext(AppContext);
    const scrollPosition = props.scrollPosition;
    const ref = props.propertyRef;
    const homePage = (window.location.pathname === "/");

    function handleOpen(){
        setContactModalPropertyRef(ref);
        setContactModalIsOpen(true);
    }

    if (ref){
        return (
            <button className="bg-primary text-white font-bold p-4 hover:bg-primary-light w-fit" onClick={handleOpen}>
                Demande de dossier
            </button>
        );
    }
    else{
        return (
            <div onClick={handleOpen} className={"block py-2 md:px-2 underline-offset-2 hover:cursor-pointer " + (scrollPosition < MIN_POS && homePage ? "" : "hover:bg-gray-100 md:hover:bg-primary")}>
                <svg
                    className={"w-5 h-5 mb-0.5 " + (scrollPosition < MIN_POS && homePage ? "fill-white group-hover:fill-primary" : "fill-primary md:group-hover:fill-white")}
                    fill="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path
                        d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"></path>
                    <path
                        d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"></path>
                </svg>
            </div>
        );
    }
}

export default ContactModalButton;
