import countries from "../../data/countries.json";
import axios from "axios";
import {useContext, useState} from "react";
import {API_ADDRESS, AppContext} from "../../App";

function ContactForm(props) {
    const ref = props.propertyRef ? props.propertyRef : "";

    const {setToastIsOpen, setToastMessage, setContactModalIsOpen} = useContext(AppContext);

    const [preferredTitle, setPreferredTitle] = useState("Monsieur");
    const [lastName, setLastName] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState("Suisse");
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState(null);

    function handleNewRow(e) {
        if (e.code === "Enter") {
            document.getElementById("message").rows = document.getElementById("message").rows + 1;
        }
    }

    function handlePreferredTitleChange(e) {
        setPreferredTitle(e.target.value);
    }

    function handleLastNameChange(e) {
        setLastName(e.target.value);
    }

    function handleNameChange(e) {
        setName(e.target.value);
    }

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handlePhoneChange(e) {
        setPhone(e.target.value);
    }

    function handleAddressChange(e) {
        setAddress(e.target.value);
    }

    function handlePostalCodeChange(e) {
        setPostalCode(e.target.value);
    }

    function handleCityChange(e) {
        setCity(e.target.value);
    }

    function handleCountryChange(e) {
        setCountry(e.target.value);
    }

    function handleMessageChange(e) {
        setMessage(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        let contactForm = {
            preferred_title: preferredTitle,
            last_name: lastName,
            name: name,
            email: email,
            phone: phone,
            address: address,
            postal_code: postalCode,
            city: city,
            country: country,
            message: message,
            link: window.location.href
        }

        if (ref !== "") {
            contactForm.ref = ref;
        }


        axios.post(API_ADDRESS + `/contact`, contactForm)
            .then(res => {
                if (res.data.sent) {
                    setContactModalIsOpen(false);
                    setToastMessage("Votre demande a été envoyée!");
                    setToastIsOpen(true);
                }
            })
            .catch(reason => {
                setErrors(reason.response.data.errors);
            });
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-center">
                <div className="relative z-0 w-1/2 mb-6 group">
                    <select name="preferred_title" required onChange={handlePreferredTitleChange}
                            className="bg-gray-50 border border-gray-300 text-primary text-sm focus:border-primary block w-full p-2.5">
                        <option value="Monsieur">Monsieur</option>
                        <option value="Madame">Madame</option>
                        <option value="Madame et Monsieur">Madame et Monsieur</option>
                        <option value="Madame, Monsieur">Madame, Monsieur</option>
                        <option value="Messieurs">Messieurs</option>
                        <option value="Mesdames">Mesdames</option>
                        <option value="Maître">Maître</option>
                        <option value="Docteur">Docteur</option>
                    </select>
                    {errors && "preferred_title" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.preferred_title[0]}</p>
                        )
                        : ""}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="last_name" id="last_name" onChange={handleLastNameChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="last_name"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Nom*
                    </label>
                    {errors && "last_name" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.last_name[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="name" id="name" onChange={handleNameChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="name"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Prénom*
                    </label>
                    {errors && "name" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.name[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="email" name="email" id="email" onChange={handleEmailChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="email"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Addresse e-mail*
                    </label>
                    {errors && "email" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.email[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="phone" id="phone" onChange={handlePhoneChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " />
                    <label htmlFor="phone"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Téléphone
                    </label>
                    {errors && "phone" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.phone[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="address" id="address" onChange={handleAddressChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="address"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Addresse*
                    </label>
                    {errors && "address" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.address[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="number" name="postal_code" id="postal_code" onChange={handlePostalCodeChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="postal_code"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        NPA*
                    </label>
                    {errors && "postal_code" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.postal_code[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="city" id="city" onChange={handleCityChange}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="city"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Localité*
                    </label>
                    {errors && "city" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.city[0]}</p>
                        )
                        : ""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <select name="country" defaultValue={"Suisse"} required onChange={handleCountryChange}
                            className="bg-gray-50 border border-gray-300 text-primary text-sm focus:border-primary block w-full p-2.5">
                        {countries.map((country) => (
                            <option key={country.code} value={country.name}>{country.name}</option>
                        ))}
                    </select>
                    {errors && "country" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.country[0]}</p>
                        )
                        : ""}
                </div>

            </div>
            <div className="relative">
                <textarea onKeyDown={handleNewRow}
                          name="message" id="message" rows="1" required onChange={handleMessageChange}
                          className="block pb-2.5 pt-4 w-full text-sm text-primary bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                          placeholder=" "
                ></textarea>
                <label htmlFor="message"
                       className="absolute text-sm text-primary duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-0 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
                    Votre message*
                </label>
                {errors && "message" in errors ?
                    (
                        <p className="mt-0 text-xs text-red-600"><span
                            className="font-medium">Ce champs est erroné!</span> {errors.message[0]}</p>
                    )
                    : ""}
            </div>
            <div className="flex justify-center pt-10 pb-5">
                <button type="submit"
                        className="text-white bg-primary hover:bg-primary-light focus:ring-4 focus:outline-none focus:ring-primary-light font-medium text-md w-full sm:w-auto px-5 py-2.5 text-center">
                    Envoyer ma demande
                </button>
            </div>
        </form>
    );
}

export default ContactForm;
