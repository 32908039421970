import {useContext, useEffect, useState} from "react";
import "./Carousel.css";
import {AppContext} from "../../App";

function CarouselDesktop(props) {
    const images = props.images;
    const details = props.details
    const numImages = images.length;

    const [index, setIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState(1);
    const [previousIndex, setPreviousIndex] = useState(numImages - 1);

    const {setCarouselDetailModalIsOpen, setCarouselDetailModalAttachments, setCarouselDetailModalId} = useContext(AppContext);

    async function transition(oldId, newId) {
        let oldImage = document.getElementById(getImageIdFromIndex(oldId));
        let newImage = document.getElementById(getImageIdFromIndex(newId));

        if (newImage && oldImage){
            newImage.classList.remove("hidden");
            newImage.classList.remove("end");
            newImage.classList.add("start");

            oldImage.classList.remove("start");
            oldImage.classList.add("end");
        }

        await new Promise(r => setTimeout(r, 1000));
    }

    function getImageIdFromIndex(id){
        const imagePrefix = "carousel_image-";

        return imagePrefix + id;
    }

    useEffect(() => {
        async function changeImage(i){
            i++;

            await transition(index,i % numImages);

            let id = i % numImages;

            setNextIndex(id === numImages - 1 ? 0 : id + 1);
            setPreviousIndex(id === 0 ? numImages - 1 : id - 1);
            setIndex(id);

            return i;
        }

        if (numImages > 1){
            let i = index;
            const changeImg = setInterval(() => {
                i = changeImage(i);
            }, 6000);
            return () => {
                clearInterval(changeImg);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    function handleNext(){
        toImage(index === (numImages - 1) ? 0 : (index + 1));
    }

    function handlePrev(){
        toImage(index === 0 ? (numImages - 1) : (index - 1));
    }

    async function toImage(id){
        await transition(index, id);

        setNextIndex(id === numImages - 1 ? 0 : id + 1);
        setPreviousIndex(id === 0 ? numImages - 1 : id - 1);
        setIndex(id);
    }

    function handleClick(){
        setCarouselDetailModalAttachments(images);
        setCarouselDetailModalId(index);
        setCarouselDetailModalIsOpen(true);
    }

    return (
        <div className={"h-[16rem] md:h-[36rem]"}>
            <div className="relative w-full h-full">
                <div className="relative overflow-hidden h-full">
                    {images.map((image, j) => {
                        return (
                        <div key={"carousel_image-" + j} id={"carousel_image-" + j} className={"w-full h-full absolute top-0" + (index !== j ? " hidden" : "")} >
                            <img src={image.url}
                                 className={"absolute cursor-pointer md:object-fill h-full block -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 " + (details ? "object-contain" : "object-cover")}
                                 alt="..."
                                 onClick={handleClick}
                            />
                        </div>);
                    })}
                </div>
                {numImages > 1 ?
                <>
                    <button type="button"
                            onClick={handlePrev}
                            className="absolute top-0 md:-left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
                        <img className={"w-48 max-h-32 shadow-lg shadow-gray-600 bg-white"} src={images[previousIndex].url} alt={""} />
                    </button>
                    <button type="button"
                            onClick={handleNext}
                            className="absolute -top-0 right-0 md:-right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
                        <img className={"w-48 max-h-32 shadow-lg shadow-gray-600 bg-white"} src={images[nextIndex].url} alt={""} />
                    </button>
                </>
                : ""}
            </div>
        </div>
    );
}

export default CarouselDesktop;
