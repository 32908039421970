import React from "react";
import ContactModalButton from "./ContactModalButton";

function ContactCard(props) {
    const agent = props.agent;
    const company = props.company;
    const ref = props.property_ref;
    const title = props.title;

    const shareUrl = encodeURI("https://foti-immo.ch/partage/" + ref);

    function formatPhoneNumber(phoneNumber) {
        let formattedPhoneNumber = phoneNumber;
        if (phoneNumber.slice(0,2) === '02' || phoneNumber.slice(0,2) === '07'){
            formattedPhoneNumber = "+41 " + phoneNumber.slice(1,3) + " " + phoneNumber.slice(3,6) + " " + phoneNumber.slice(6);
        }
        else if (phoneNumber.slice(0,3) === '+41'){
            formattedPhoneNumber = phoneNumber.slice(0,3) + " " + phoneNumber.slice(3,5) + " " + phoneNumber.slice(5,8) + " " + phoneNumber.slice(8);
        }
        else if (phoneNumber.slice(0,4) === '0041'){
            formattedPhoneNumber = "+41 " + phoneNumber.slice(4,6) + " " + phoneNumber.slice(6,9) + " " + phoneNumber.slice(9);
        }

        return formattedPhoneNumber;
    }

    return (
        <div className="mb-2">
            <div className="py-2 px-6 border border-black w-fit flex justify-center flex-col gap-y-2">
                <div className="flex justify-center">
                    <img className="w-52" src={require("../../media/images/icons/logo.png")} alt="Foti Immobilier" />
                </div>
                <div className="flex justify-center">
                    <ContactModalButton propertyRef={ref} />
                </div>
                <div className="flex justify-center flex-col text-center">
                    <p className="font-bold text-lg">{company.name}</p>
                    <p className="font-bold">{agent.givenName} {agent.familyName}</p>
                    <p className="mt-2">{company.street} {company.streetNumber}</p>
                    <p>{company.postalCode} {company.locality}</p>
                    {formatPhoneNumber(company.phone) ?
                        <div>
                            Tél.
                            <a className="text-primary hover:text-primary-light hover:underline font-bold ml-1" href={"tel:" + company.phone}>
                                {formatPhoneNumber(company.phone)}
                            </a>
                        </div>
                    : ""}
                    {formatPhoneNumber(agent.mobile) ?
                        <div>
                            Mob.
                            <a className="text-primary hover:text-primary-light hover:underline font-bold ml-1" href={"tel:" + agent.mobile}>
                                {formatPhoneNumber(agent.mobile)}
                            </a>
                        </div>
                    : ""}
                    <a className="mt-2 text-primary hover:text-primary-light underline font-bold" href="/">www.foti-immo.ch</a>
                </div>
            </div>
            <div>
                <div className="flex justify-center font-bold my-1">Partager l'objet</div>
                <div className="flex justify-center gap-x-3">
                    <a href={"whatsapp://send?text="+shareUrl} rel="noreferrer" target="_blank">
                        <svg className="w-4 h-4 text-primary hover:text-primary-light" fill="currentColor"
                             viewBox="0 0 308 308">
                            <g>
                                <path d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                                    c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                                    c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                                    c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                                    c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                                    c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                                    c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                                    c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                                    c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                                    C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                                <path d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                                    c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                                    c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                                     M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                                    l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                                    c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                                    C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                            </g>
                        </svg>
                    </a>
                    <a href={"sms:?body="+shareUrl} rel="noreferrer" target="_blank">
                        <svg className="w-4 h-4 text-primary hover:text-primary-light" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M5.5 6.5h.01m4.49 0h.01m4.49 0h.01M18 1H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
                        </svg>
                    </a>
                    <a href={"https://www.facebook.com/sharer.php?u="+shareUrl+"&t="+title} rel="noreferrer" target="_blank">
                        <svg className="w-4 h-4 text-primary hover:text-primary-light" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                            <path fillRule="evenodd"
                                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                  clipRule="evenodd"/>
                        </svg>
                    </a>
                    <a href={"https://www.linkedin.com/sharing/share-offsite/?url="+shareUrl} rel="noreferrer" target="_blank">
                        <svg className="w-4 h-4 text-primary hover:text-primary-light" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 15 15">
                            <path fillRule="evenodd"
                                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                                  clipRule="evenodd"/>
                            <path d="M3 5.012H0V15h3V5.012Z"/>
                        </svg>
                    </a>
                    <a href={"mailto:?subject="+title+"&body="+shareUrl} rel="noreferrer" target="_blank">
                        <svg className="w-4 h-4 text-primary hover:text-primary-light" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                            <path
                                d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                            <path
                                d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactCard;
