import {useParams} from "react-router-dom";
import PropertySearchList from "../components/Property/PropertySearchList";

function Project() {
    const {ref} = useParams();

    const params = {
        actionType: null,
        budget: null,
        cities: null,
        types: null,
        promotion: (ref ? ref : null),
        page: 1
    };

    return (
        <div>
            <div className="py-10 pt-52 min-h-screen">
                <PropertySearchList promotionRef={ref} params={params}/>
            </div>
        </div>
    );
}

export default Project;
