import {useSearchParams} from "react-router-dom";
import PropertySearchList from "../components/Property/PropertySearchList";

function Buy() {
    const [searchParams] = useSearchParams();

    const params = {
        actionType: searchParams.get("actionType") !== null ? searchParams.get("actionType") : "Acheter",
        budget: searchParams.get("budget"),
        cities: searchParams.get("cities") !== null && searchParams.get("cities") !== "" ? searchParams.get("cities").split(",") : null,
        types: searchParams.get("types") !== null && searchParams.get("types") !== "" ? searchParams.get("types").split(",") : null,
        page: 1
    };

    return (
        <div>
            <div className="py-10 pt-32 md:pt-52 min-h-screen">
                <PropertySearchList params={params} />
            </div>
        </div>
    );
}

export default Buy;
