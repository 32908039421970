
function PropertySkeleton() {
    return (
        <div className="flex justify-center">
            <div className="relative">
                <div className="bg-primary w-[21rem] min-w-fit h-[29rem] flex flex-col">
                    <div>
                        <div className="flex items-center justify-center h-48 mb-4 bg-primary-light animate-pulse rounded">
                            <svg className="w-12 h-12 text-white" xmlns="http://www.w3.org/2000/svg"
                                 aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                                <path
                                    d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex-grow flex flex-col justify-between p-5">
                        <div className="text-white">
                            <div className="text-lg font-bold place-self-start">
                                <div className="h-2.5 animate-pulse bg-white rounded-full w-1/2 mb-4"></div>
                            </div>
                            <div className="text-sm">
                                <div className="h-2 bg-white rounded-full animate-pulse mb-2.5"></div>
                                <div className="h-2 bg-white rounded-full animate-pulse mb-2.5"></div>
                                <div className="h-2 bg-white rounded-full animate-pulse mb-2.5"></div>
                                <div className="h-2 bg-white rounded-full animate-pulse mb-2.5"></div>
                                <div className="h-2 bg-white rounded-full animate-pulse"></div>
                            </div>
                        </div>
                        <div className="text-white">
                            <div className="font-bold mb-4 text-lg">
                                <div className="h-2.5 animate-pulse bg-white rounded-full w-1/2 mb-4"></div>
                            </div>
                            <div className="grid grid-cols-3 divide-white divide-x">
                                <div className="flex flex-col text-center items-center animate-pulse">
                                    <div className="h-2 bg-white rounded-full mb-2.5 w-4"></div>
                                    <svg className="w-5 rounded-full text-white" xmlns="http://www.w3.org/2000/svg"
                                         aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                                        <path
                                            d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
                                    </svg>
                                </div>
                                <div className="flex flex-col text-center items-center animate-pulse">
                                    <div className="h-2 bg-white rounded-full mb-2.5 w-4"></div>
                                    <svg className="w-5 rounded-full text-white" xmlns="http://www.w3.org/2000/svg"
                                         aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                                        <path
                                            d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
                                    </svg>
                                </div>
                                <div className="flex flex-col text-center items-center animate-pulse">
                                    <div className="h-2 bg-white rounded-full mb-2.5 w-4"></div>
                                    <svg className="w-5 rounded-full text-white" xmlns="http://www.w3.org/2000/svg"
                                         aria-hidden="true" fill="currentColor" viewBox="0 0 640 512">
                                        <path
                                            d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertySkeleton;
