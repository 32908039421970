import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import '../../media/styles/styles.css';
import PromotionTable from "./PromotionTable";
import {API_ADDRESS} from "../../App";
import Carousel from "../Carousel/Carousel";

function PromotionDetails() {
    const [promotion, setPromotion] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { ref } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/promotions/' + ref)
                .then(res => res.json())
                .then(json => {
                    setPromotion(json.data);
                    setIsLoading(false);
                });
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (<div></div>);
    }
    return (
        <div className="pt-40">
            <div className="container mx-auto md:py-10 md:w-5/6 flex justify-center flex-col">
                <Carousel images={promotion.attachments} details={true}/>
                <div className="text-2xl mt-4 text-center px-4 md:px-0">
                    {promotion.subtitle}
                </div>
                <div className="flex self-center flex-col md:w-4/6 text-sm mt-2 styled-ul px-4 md:px-0"
                     dangerouslySetInnerHTML={{__html: promotion.description}}></div>
            </div>
            <div className="container mx-auto md:w-4/6 text-sm">
                <h2 className="text-2xl font-bold mt-6 pb-4 px-4 md:px-0">Biens de la promotion</h2>
                <div className="relative max-w-full overflow-x-auto mb-6 px-4 md:px-0">
                    <PromotionTable promotion={promotion}/>
                </div>
            </div>
        </div>
    );
}

export default PromotionDetails;
