import React from "react";
import CarouselMobile from "../Carousel/CarouselMobile";
import PropertyPictograms from "./PropertyPictograms";
import ContactCard from "../Contact/ContactCard";
import specifications from "../../data/specifications.json";
import '../../media/styles/styles.css';
import status from "../../data/status.json";

function PropertyDetailsMobile(props) {
    const property = props.property;
    const promotion = props.promotion;

    function toHumanReadable(value){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }

    function specValueToHumanReadable(spec) {
        if(spec.name === "floor" && spec.value === 0){
            return "Rez";
        }

        if(specifications[spec.name]?.unit === "CHF" || specifications[spec.name]?.unit === "m" || specifications[spec.name]?.unit === "m2" || specifications[spec.name]?.unit === "m3"){
            return toHumanReadable(spec.value);
        }

        return spec.value;
    }

    return (
        <div className="pt-32 md:pt-52">
            <div className="container mx-auto md:py-10 w-full md:w-3/6 flex justify-center flex-col">
                <CarouselMobile images={property.attachments} details={true}/>
                {promotion ?
                    <div className="flex justify-center mt-6">
                        <a className="rounded-md text-md bg-primary text-white font-bold py-3 px-5 w-fit hover:bg-primary-light"
                           href={"/promotion/" + property.ref.split("-")[0]}>
                            Voir la promotion
                        </a>
                    </div>
                    : ""}
                <div className="uppercase text-md mb-2 mt-6 text-center">
                    <strong>{property.type === "buy" ? "à vendre" : "à louer"}</strong> –
                    réf. <strong>{property.ref}</strong>
                </div>
                {property.status !== "active" ? (
                        <div className="flex justify-center mb-2">
                            <div className="text-lg uppercase bg-red-600 text-white font-bold py-1 px-4 w-fit">
                                {status[property.status]}
                            </div>
                        </div>
                    )
                    :
                    (<div></div>)}
                <div className="text-2xl text-center">
                    {property.name}
                </div>
                <div className="text-sm text-center">
                    {property.subtitle}
                </div>
                {property.video ?
                    <div className="flex justify-center mt-2 mb-2">
                        <a href={property.video.url} rel="noreferrer" target="_blank">
                            <div className="text-sm uppercase bg-red-600 text-white font-bold py-1 px-4 w-fit">
                                Vidéo du bien
                            </div>
                        </a>
                    </div>
                :""}
                <div className="flex justify-center mt-2">
                    <div className="rounded-md text-sm bg-primary text-white font-bold py-3 px-5 w-fit">
                        {property.price === 0 ?
                            "Prix sur demande"
                            :
                            <>
                                CHF {property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}.–{property.type === "rent" ? "/mois" : ""}
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="container mx-auto md:w-4/6 text-sm pt-4 md:pt-0">
                <PropertyPictograms specifications={property.specifications.concat(property.importantSpecifications)} features={property.features}/>
                <div className="md:flex justify-between gap-x-4">
                    <div className="py-4 px-4 md:px-0">
                        <h2 className="text-2xl font-bold">Présentation</h2>
                        <div className="py-2 pr-4 styled-ul" dangerouslySetInnerHTML={{__html: property.description}}>
                        </div>
                        <h2 className="text-2xl font-bold mt-6">Données techniques</h2>
                        {property.importantSpecifications.map((spec, index) => (
                            <div key={index} className="py-1" >
                                {specifications[spec.name]?.name}: <strong>{specValueToHumanReadable(spec)} {specifications[spec.name]?.unit ? specifications[spec.name].unit : ""}</strong>
                            </div>
                        ))}
                        {property.specifications.map((spec, index) => (
                            <div key={index} className="py-1" >
                                {specifications[spec.name]?.name}: <strong>{specValueToHumanReadable(spec)} {specifications[spec.name]?.unit ? specifications[spec.name].unit : ""}</strong>
                            </div>
                        ))}
                    </div>

                    <div className="py-4 px-4 md:px-0 min-w-fit">
                        <h2 className="text-2xl font-bold mb-2">Contact</h2>
                        <div className="flex justify-center md:block ">
                            <ContactCard
                                company={{
                                    name: property.contact.company,
                                    phone: property.contact.companyPhone,
                                    locality: property.contact.companyLocality,
                                    postalCode: property.contact.companyPostalCode,
                                    street: property.contact.companyStreet,
                                    streetNumber: property.contact.companyStreetNumber
                                }}
                                agent={{
                                    givenName: property.contact.agentGivenName,
                                    familyName: property.contact.agentFamilyName,
                                    mobile: property.contact.agentMobile,
                                    phone: property.contact.agentPhone
                                }}
                                property_ref={property.ref}
                                title={property.name}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropertyDetailsMobile;
