import React, {useEffect, useState} from "react";
import Dropdown from "./Dropdown";
import {useSearchParams} from "react-router-dom";
import {API_ADDRESS} from "../../../App";

export const searchFormDefault = {
    actionType: "Acheter",
    types: [],
    cities: [],
    budget: 0,
    sold: false
};

export const SearchFormContext = React.createContext({
    searchForm: searchFormDefault,
    setSearchForm: (searchForm) => {
        this.searchForm = searchForm;
    }
});

export function copySearchForm(searchForm) {
    return JSON.parse(JSON.stringify(searchForm));
}

function SearchForm(props) {
    const [searchParams] = useSearchParams();
    const [searchForm, setSearchForm] = useState({
        actionType: (window.location.pathname === "/louer" || searchParams.get("actionType") === "Louer") ? "Louer" : searchFormDefault.actionType,
        types: searchParams.get("types") ? searchParams.get("types").split(",") : searchFormDefault.types,
        cities: searchParams.get("cities") ? searchParams.get("cities").split(",") : searchFormDefault.cities,
        budget: searchParams.get("budget") ? searchParams.get("budget") : searchFormDefault.budget,
        sold: window.location.pathname === "/vendus" ? true : searchFormDefault.sold
    });
    const value = {searchForm, setSearchForm};

    const budgetRanges = [
        {name: "Budget indifférent", value: "0-999999999"},
        {name: "0 - 100'000", value: "0-100000"},
        {name: "100'000 - 1'000'000", value: "100000-1000000"},
        {name: "1'000'000 - 1'500'000", value: "1000000-1500000"},
        {name: "1'500'000 - 2'000'000", value: "1500000-2000000"},
        {name: "2'000'000 - 3'000'000", value: "2000000-3000000"},
        {name: "3'000'000 - 4'000'000", value: "3000000-4000000"},
        {name: "4'000'000 - 5'000'000", value: "4000000-5000000"},
        {name: "5'000'000 - 7'000'000", value: "5000000-7000000"},
        {name: "7'000'000 - 10'000'000", value: "7000000-10000000"},
        {name: "10'000'000+", value: "10000000-999999999"},
    ]

    const [cities, setCities] = useState([]);
    const [isCitiesLoading, setIsCitiesLoading] = useState(true);

    const [types, setTypes] = useState([]);
    const [isTypesLoading, setIsTypesLoading] = useState(true);

    const [actionType, setActionType] = useState(searchForm.actionType);

    useEffect(() => {
        const fetchCities = async () => {
            fetch(API_ADDRESS + '/data/cities')
                .then(res => res.json())
                .then(json => {
                    setCities(json.data);
                    setIsCitiesLoading(false);
                });
        };

        const fetchTypes = async () => {
            fetch(API_ADDRESS + '/data/types')
                .then(res => res.json())
                .then(json => {
                    setTypes(json.data);
                    setIsTypesLoading(false);
                });
        };

        fetchCities();
        fetchTypes();
    }, []);

    function updateSearchForm(field, value) {
        let tempSearchForm = copySearchForm(searchForm);

        tempSearchForm[field] = value;

        setSearchForm(tempSearchForm);
    }

    function handleActionTypeChange(e) {
        let currActionType = e.target.checked ? "Acheter" : "Louer";

        setActionType(currActionType);

        updateSearchForm("actionType", currActionType);
    }

    function submitForm() {
        let getParams = "?actionType=" + searchForm.actionType +
            (searchForm.budget !== searchFormDefault.budget ? "&budget=" + (typeof searchForm.budget === "string" ? searchForm.budget : searchForm.budget.value) : "") +
            (searchForm.cities.length > 0 ? "&cities=" + searchForm.cities.map((city) => {
                if (typeof city === "string") {
                    return city;
                } else {
                    return city.postalCode;
                }
            }).join(",") : "") +
            (searchForm.types.length > 0 ? "&types=" + searchForm.types.map((type) => {
                if (typeof type === "string") {
                    return type;
                } else {
                    return type.value;
                }
            }).join(",") : "");

        if(!searchForm.sold){
            window.location.href = (searchForm.actionType === "Acheter" ? "/acheter" : "/louer") + getParams;
        }
        else{
            window.location.href = "/vendus" + getParams;
        }
    }


    return (
        <div className="h-fit">
            <label htmlFor="actionType"
                   className="border border-primary shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-full bg-white p-1 mb-4 gap-x-2">
                <input type="checkbox" name="actionType" id="actionType" checked={actionType === "Acheter"}
                       className="sr-only" value="Acheter" onChange={handleActionTypeChange}/>
                <span
                    className={"transition ease-out text-lg flex items-center rounded-full p-2 " + (actionType === "Acheter" ? "text-white bg-primary" : "text-primary hover:bg-gray-200")}>
                      Acheter
                   </span>
                <span
                    className={"transition ease-out text-lg flex items-center rounded-full p-2 " + (actionType === "Louer" ? "text-white bg-primary" : "text-primary hover:bg-gray-200")}>
                      Louer
                   </span>
            </label>
            {window.location.pathname === "/" ?
            <div
                className="border border-white shadow-card relative inline-flex cursor-pointer select-none
                   items-center justify-center rounded-full bg-white mb-4 gap-x-2 ml-3">
                <div
                    className="transition ease-out text-lg flex items-center rounded-full p-2 text-white bg-primary hover:bg-primary-light group">
                    <a className="ml-1 flex items-center" href="/vendre">
                        Estimation
                        <svg className="w-4 h-4 rotate-90 transition-transform group-hover:rotate-45 mx-2"
                             aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M5 13V1m0 0L1 5m4-4 4 4"/>
                        </svg>
                    </a>
                </div>
            </div>
            :""}
            <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x divide-primary md:h-24 mb-2 border border-primary">
                <SearchFormContext.Provider value={value}>
                    <div className="w-full md:w-2/3 h-16 md:h-auto bg-white">
                        {!isCitiesLoading ?
                            <Dropdown title={"Localité"} name={"cities"} items={cities} values={"postalCode"}/>
                        :""}

                    </div>
                    <div className="w-full md:w-2/3 h-16 md:h-auto bg-white">
                        {!isTypesLoading ?
                            <Dropdown title={"Type"} name={"types"} items={types} values={"value"}/>
                        :""}

                    </div>
                    <div className="w-full md:w-2/3 h-16 md:h-auto">
                        <Dropdown type={"radio"} title={"Budget (CHF)"} name={"budget"} items={budgetRanges}
                                  values={"value"}/>
                    </div>
                </SearchFormContext.Provider>
                <div className="w-full md:h-full md:w-1/5">
                    <button
                        className="w-full h-16 md:h-full p-3 bg-primary text-white hover:bg-primary-light focus:ring-4 focus:outline-none focus:ring-primary-light group"
                        onClick={submitForm}>
                        <div className="w-full h-full group-hover:rotate-90 transition flex justify-center items-center">
                            <svg className="w-6 h-6" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SearchForm;