import SellForm from "../components/Contact/SellForm/SellForm";

function Sell() {
    return (
        <div className="pt-28 md:pt-52 pb-10 md:h-screen">
            <SellForm />
        </div>
    );
}

export default Sell;
