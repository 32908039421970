import {useContext, useState} from "react";
import {copySellForm, SellFormContext} from "./SellForm";

function Address(props) {
    const { sellForm, setSellForm } = useContext(SellFormContext);
    const errors = props.errors;

    const [city, setCity] = useState(sellForm.city);
    const [postalCode, setPostalCode] = useState(sellForm.postalCode);
    const [address, setAddress] = useState(sellForm.address);

    function updateSellForm(field, value){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm[field] = value;

        setSellForm(tempSellForm);
    }

    function handleAddressChange(e){
        setAddress(e.target.value);

        updateSellForm('address', e.target.value);
    }
    function handlePostalCodeChange(e){
        setPostalCode(e.target.value);

        updateSellForm('postalCode', e.target.value);
    }
    function handleCityChange(e){
        setCity(e.target.value);

        updateSellForm('city', e.target.value);
    }

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="city" id="city" onChange={handleCityChange} value={city}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="city"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Localité *
                    </label>
                    { errors && "city" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.city[0]}</p>
                        )
                        :""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="number" name="postal_code" id="postal_code" onChange={handlePostalCodeChange} value={postalCode}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="postal_code"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        NPA *
                    </label>
                    { errors && "postal_code" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.postal_code[0]}</p>
                        )
                        :""}
                </div>
            </div>
            <div className="relative z-0 w-full mb-6 group">
                <input type="text" name="address" id="address" onChange={handleAddressChange} value={address}
                       className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                       placeholder=" " required/>
                <label htmlFor="address"
                       className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                    Addresse
                </label>
                { errors && "address" in errors ?
                    (
                        <p className="mt-0 text-xs text-red-600"><span
                            className="font-medium">Ce champs est erroné!</span> {errors.address[0]}</p>
                    )
                    :""}
            </div>
        </div>
    );
}

export default Address;
