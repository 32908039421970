import {useContext, useState} from "react";
import {copySellForm, SellFormContext} from "./SellForm";

function GeneralSpecs() {
    const { sellForm, setSellForm } = useContext(SellFormContext);

    const [livingArea,setLivingArea] = useState(sellForm.livingArea);
    const [terrainArea,setTerrainArea] = useState(sellForm.terrainArea);
    const [constructionYear,setConstructionYear] = useState(sellForm.constructionYear);
    const [rooms,setRooms] = useState(sellForm.rooms);
    const [state, setState] = useState(sellForm.state);

    const stateList = [
        "À rénover",
        "Bon état",
        "Rénové"
    ]

    function updateSellForm(field, value){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm[field] = value;

        setSellForm(tempSellForm);
    }

    function handleLivingAreaChange(e){
        setLivingArea(e.target.value);

        updateSellForm("livingArea", e.target.value)
    }
    function handleTerrainAreaChange(e){
        setTerrainArea(e.target.value);

        updateSellForm("terrainArea", e.target.value);
    }
    function handleConstructionYearChange(e){
        setConstructionYear(e.target.value);

        updateSellForm("constructionYear", e.target.value);
    }
    function handleRoomsChange(e){
        setRooms(e.target.value);

        updateSellForm("rooms", e.target.value)
    }
    function handleStateChange(currState){
        setState(currState);

        updateSellForm("state", currState);
    }

    return (
        <div>
            <div className="grid md:grid-cols-2 gap-x-6 gap-y-4 mb-4">
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="livingArea" className="block font-medium p-1">Surface habitable (m<span className="align-super text-xs">2</span>) *</label>
                        <input
                            className="border border-gray-300 rounded-lg focus:ring-primary focus:border-primary p-1 w-16"
                            type="number" min="20" max="800" value={livingArea} onChange={handleLivingAreaChange} />
                    </div>
                    <input id="livingArea" type="range" min="20" max="800" value={livingArea} onChange={handleLivingAreaChange} step="1"
                           className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-light hover:accent-primary" />

                </div>
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="terrainArea" className="block font-medium p-1">Surface terrain (m<span className="align-super text-xs">2</span>) *</label>
                        <input
                            className="border border-gray-300 rounded-lg focus:ring-primary focus:border-primary p-1 w-16"
                            type="number" min="50" max="5000" value={terrainArea} onChange={handleTerrainAreaChange} />
                    </div>
                    <input id="terrainArea" type="range" min="50" max="5000" value={terrainArea} onChange={handleTerrainAreaChange} step="1"
                           className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-light hover:accent-primary" />

                </div>
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="constructionYear" className="block font-medium p-1">Année de construction *</label>
                        <input
                            className="border border-gray-300 rounded-lg focus:ring-primary focus:border-primary p-1 w-16"
                            type="number" min="1850" max="2026" value={constructionYear} onChange={handleConstructionYearChange} />
                    </div>
                    <input id="constructionYear" type="range" min="1850" max="2026" value={constructionYear} onChange={handleConstructionYearChange} step="1"
                           className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-light hover:accent-primary" />

                </div>
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="rooms" className="block font-medium p-1">Nombre de pièces *</label>
                        <input
                            className="border border-gray-300 rounded-lg focus:ring-primary focus:border-primary p-1 w-16"
                            type="number" min="1" max="20" value={rooms} onChange={handleRoomsChange} />
                    </div>
                    <input id="rooms" type="range" min="1" max="20" value={rooms} onChange={handleRoomsChange} step="0.5"
                           className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-light hover:accent-primary" />

                </div>
            </div>
            <div>
                <h3 className="block font-medium p-1 mb-2">
                    État *
                </h3>
                <ul className="grid w-full gap-6 md:grid-cols-3">
                    {stateList.map((currState, index) => (
                        <li key={index}>
                            <label onClick={() => handleStateChange(currState)}
                                   className={"inline-flex items-center justify-between w-full p-3 md:p-5 border " +
                                       "rounded-lg cursor-pointer " +
                                       (state === currState ?
                                           "border-primary text-primary bg-primary-transparent" :
                                           "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                <div className="block">
                                    <div className="w-full text-lg font-semibold">{currState}</div>
                                </div>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default GeneralSpecs;
