import React, {useEffect, useState} from "react";
import PropertyDetailsMobile from "./PropertyDetailsMobile";
import PropertyDetailsDesktop from "./PropertyDetailsDesktop";
import {API_ADDRESS} from "../../App";
import {useSearchParams} from "react-router-dom";

function PropertyDetails() {
    const [searchParams] = useSearchParams();

    const [property, setProperty] = useState({});
    const [promotion, setPromotion] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const ref = searchParams.get("ref");

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/properties/' + ref)
                .then(res => res.json())
                .then(json => {
                    if (!json.data) {
                        window.location.href = "/";
                    } else {
                        setProperty(json.data);
                        if ("promotion" in json.data) {
                            setPromotion(json.data.promotion);
                        }
                        setIsLoading(false);
                    }
                });
        };

        fetchData();

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isMobile = width <= 768;

    if (isLoading) {
        return (<div className="h-screen"></div>);
    }

    if(!isLoading){
        if (isMobile) {
            return (
                <PropertyDetailsMobile property={property} promotion={promotion}/>
            );
        }

        return (
            <div className="pt-32 md:pt-52 flex items-center flex-col">
                <PropertyDetailsDesktop property={property} promotion={promotion}/>
            </div>
        );
    }
}

export default PropertyDetails;
