import {useContext, useEffect} from "react";
import {AppContext} from "../App";

function Toast() {
    const duration = 10;

    const {toastIsOpen, toastMessage, toastType, setToastIsOpen} = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            setToastIsOpen(false);
        }, duration * 1000);
    });

    if (!toastIsOpen){
        return (<div></div>);
    }

    return (
        <div className="fixed z-50 top-28 md:top-52 right-0 md:right-2">
            <div id="toast"
                 className={(toastType === "warning" ? "text-red-950 bg-red-300 divide-red-950 " : "text-primary bg-white divide-gray-200 ") +
                     "flex items-center w-full max-w-xs p-4 space-x-4 divide-x rounded-lg shadow space-x"}
                 role="alert">
                {toastType === "info" ? (
                    <svg className="w-5 h-5 text-primary rotate-45"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"/>
                    </svg>
                )
                :
                toastType === "warning" ?
                    <svg className="w-8 h-8 text-red-950"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                : ""}
                <div className="pl-4 text-xs md:text-sm font-normal">{toastMessage}</div>
                <button type="button" onClick={() => setToastIsOpen(false)}
                        className={"ml-auto pl-4 text-gray-400 hover:text-gray-900 p-1.5 inline-flex items-center justify-center h-8 w-8 " +
                            (toastType === "warning" ? "text-red-950 bg-red-300 divide-red-950 " : "text-primary bg-white divide-gray-200")}
                        data-dismiss-target="#toast-default" aria-label="Close">
                    <span className="sr-only">Close</span>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Toast;