import {useContext, useState} from "react";
import {copySellForm, SellFormContext} from "./SellForm";

function Address(props) {
    const { sellForm, setSellForm } = useContext(SellFormContext);
    const errors = props.errors;

    const [title, setTitle] = useState(sellForm.title);
    const [lastName, setLastName] = useState(sellForm.lastName);
    const [name, setName] = useState(sellForm.name);
    const [phone, setPhone] = useState(sellForm.phone);
    const [email, setEmail] = useState(sellForm.email);
    const [motive, setMotive] = useState(sellForm.motive);
    function updateSellForm(field, value){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm[field] = value;

        setSellForm(tempSellForm);
    }

    function handleTitleChange(e){
        setTitle(e.target.value);

        updateSellForm('title', e.target.value);
    }

    function handleLastNameChange(e){
        setLastName(e.target.value);

        updateSellForm('lastName', e.target.value);
    }

    function handleNameChange(e){
        setName(e.target.value);

        updateSellForm('name', e.target.value);
    }

    function handlePhoneChange(e){
        setPhone(e.target.value);

        updateSellForm('phone', e.target.value);
    }

    function handleEmailChange(e){
        setEmail(e.target.value);

        updateSellForm('email', e.target.value);
    }

    function handleMotiveChange(e){
        setMotive(e.target.value);

        updateSellForm('motive', e.target.value);
    }

    return (
        <div>
            <div className="flex md:justify-center">
                <div className="text-primary -mb-2 text-left w-1/2">
                    Titre* :
                </div>
            </div>
            <div className="md:flex md:justify-center">
                <div className="grid grid-cols-2 gap-x-4 md:w-1/2">
                    <div className="flex items-center pl-4">
                        <input checked={title === "Madame"} id="radio-madam" type="radio" value="Madame" name="radio-title" onChange={handleTitleChange}
                               className="text-primary"
                        />
                        <label htmlFor="radio-madam"
                               className="w-full py-4 ml-2 text-sm font-medium text-primary">Madame</label>
                    </div>
                    <div className="flex items-center pl-4">
                        <input checked={title === "Monsieur"} id="radio-sir" type="radio" value="Monsieur" name="radio-title" onChange={handleTitleChange}
                               className="text-primary"
                        />
                        <label htmlFor="radio-sir"
                               className="w-full py-4 ml-2 text-sm font-medium text-primary">Monsieur</label>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10">
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="last_name" id="last_name" onChange={handleLastNameChange} value={lastName}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="last_name"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Nom*
                    </label>
                    { errors && "last_name" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.last_name[0]}</p>
                        )
                        :""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="name" id="name" onChange={handleNameChange} value={name}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="name"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Prénom*
                    </label>
                    { errors && "name" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.name[0]}</p>
                        )
                        :""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="text" name="phone" id="phone" onChange={handlePhoneChange} value={phone}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" "/>
                    <label htmlFor="phone"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Téléphone
                    </label>
                    { errors && "phone" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.phone[0]}</p>
                        )
                        :""}
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="email" name="email" id="email" onChange={handleEmailChange} value={email}
                           className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                           placeholder=" " required/>
                    <label htmlFor="email"
                           className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                        Addresse e-mail*
                    </label>
                    { errors && "email" in errors ?
                        (
                            <p className="mt-0 text-xs text-red-600"><span
                                className="font-medium">Ce champs est erroné!</span> {errors.email[0]}</p>
                        )
                        :""}
                </div>
            </div>
            <div className="text-primary -mb-2">
                Motif de votre demande* :
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4">
                <div className="flex items-center pl-4">
                    <input checked={motive === "Acheter"} id="radio-buy" type="radio" value="Acheter" name="radio-motive" onChange={handleMotiveChange}
                           className="text-primary"
                    />
                    <label htmlFor="radio-buy"
                           className="w-full py-4 ml-2 text-sm font-medium text-primary">Acheter</label>
                </div>
                <div className="flex items-center pl-4">
                    <input checked={motive === "Vendre"} id="radio-sell" type="radio" value="Vendre" name="radio-motive" onChange={handleMotiveChange}
                           className="text-primary"
                    />
                    <label htmlFor="radio-sell"
                           className="w-full py-4 ml-2 text-sm font-medium text-primary">Vendre</label>
                </div>
                <div className="flex items-center pl-4">
                    <input checked={motive === "Par curiosité"} id="radio-curiosity" type="radio" value="Par curiosité" name="radio-motive" onChange={handleMotiveChange}
                           className="text-primary"
                    />
                    <label htmlFor="radio-curiosity"
                           className="w-full py-4 ml-2 text-sm font-medium text-primary">Par curiosité</label>
                </div>
                <div className="flex items-center pl-4">
                    <input checked={motive === "Autre"} id="radio-other" type="radio" value="Autre" name="radio-motive" onChange={handleMotiveChange}
                           className="text-primary"
                    />
                    <label htmlFor="radio-other"
                           className="w-full py-4 ml-2 text-sm font-medium text-primary">Autre</label>
                </div>
            </div>
        </div>
    );
}

export default Address;
