function PromotionTableRow(props) {
    const building = props.building;
    const promotionRef = props.promotionRef;

    const properties = building.properties.sort((a,b) => {
        const lotA = parseFloat(a.ref.split("-")[a.ref.split("-").length - 1]);
        const lotB = parseFloat(b.ref.split("-")[b.ref.split("-").length - 1]);

        return lotA - lotB;
    });

    return (
            <>
                <tr className="border-t border-primary font-bold">
                    <th scope="row"
                        className="hidden md:table px-6 py-4">
                    </th>
                    <td className="px-6 py-4">
                        Immeuble {building.ref.split('-')[1]}
                    </td>
                    <td className="hidden md:table px-6 py-4">
                    </td>
                    <td className="hidden md:table px-6 py-4">
                    </td>
                    <td className="hidden md:table px-6 py-4">
                    </td>
                    <td className="px-6 py-4">
                    </td>
                    <td className="hidden md:table px-6 py-4">
                    </td>
                    <td className="md:hidden px-6 py-4">
                    </td>
                </tr>
                {properties.map((property, i) => (
                    <tr key={i} className="border-t border-primary">
                        <th scope="row"
                            className="hidden md:table-cell px-6 py-4 font-medium">
                                {property.ref.split("-")[property.ref.split("-").length - 1]}
                        </th>
                        <td className="px-6 py-4 font-medium pl-12">
                            <a className="hover:text-primary-light"
                               href={"/promotion/"+promotionRef+"/propriete?ref=" + property.ref + "&back=" + window.location.href}>
                                {property.name}
                            </a>
                        </td>
                        <td className="hidden md:table-cell px-6 py-4 text-center">
                            {property.specifications.find((spec) => spec.name === "floor")?.value === 0 ? "Rez" : property.specifications.find((spec) => spec.name === "floor")?.value ? property.specifications.find((spec) => spec.name === "floor").value : "-"}
                        </td>
                        <td className="table-cell px-6 py-4 text-center">
                            {property.importantSpecifications.find((spec) => spec.name === "number_of_rooms")?.value ? property.importantSpecifications.find((spec) => spec.name === "number_of_rooms").value : "-"}
                        </td>
                        <td className="hidden md:table-cell px-6 py-4 text-center">
                            {property.importantSpecifications.find((spec) => spec.name === "area_bwf")?.value ? property.importantSpecifications.find((spec) => spec.name === "area_bwf").value + " m2" : property.specifications.find((spec) => spec.name === "area_nwf")?.value ? property.specifications.find((spec) => spec.name === "area_nwf").value + " m2" : "-"}
                        </td>
                        <td className="px-6 py-4 text-right">
                            CHF {property.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")}.–{property.type === "rent" ? "/mois" : ""}
                        </td>
                        <td className="hidden md:table-cell px-6 py-4 text-center">
                            {property.status === "active" ? "Disponible" : property.status === "reserved" ? "Réservé" : property.status === "taken" ? "Vendu" : "Indisponible"}
                        </td>
                    </tr>
                ))}
            </>
    );
}

export default PromotionTableRow;
