


function PropertyPictograms(props) {
    const specs = props.specifications;
    const features = props.features;
    let livable_area = 0;
    let rooms = 0;
    let volume = 0;
    let terrain_area = 0;
    let garage = typeof features !== "undefined" ? features["has-garage"] : false;
    let parking = typeof features !== "undefined" ? features["has-parking"] : false;

    for(let spec of specs){
        if(spec.name === "area_bwf"){
            livable_area = spec.value;
        }
        if(spec.name === "number_of_rooms"){
            rooms = spec.value;
        }
        if(spec.name === "volume_sia_gv" || spec.name === "volume_sia_116_gv" || spec.name === "volume_gva" || spec.name === "volume_unknown_gv"){
            volume = spec.value;
        }
        if(spec.name === "area_sia_gsf"){
            terrain_area = spec.value;
        }
    }

    return (
        <div>
            <div className="grid grid-cols-3 md:flex md:flex-row md:justify-center gap-x-0 md:gap-x-20">
                { livable_area ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/livable_area.png")} alt="Surface habitable" />
                        <div className="text-center -mt-1.5">
                            {livable_area} m<span className="align-super text-xs">2</span>
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
                { rooms ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/rooms.png")} alt="Nombre de pièces" />
                        <div className="text-center mt-0">
                            {rooms} pièce{rooms > 1 ? "s" : ""}
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
                { volume ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/volume.png")} alt="Volume" />
                        <div className="text-center -mt-1.5">
                            {volume} m<span className="align-super text-xs">3</span>
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
                {terrain_area ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/terrain_area.png")} alt="Surface terrain" />
                        <div className="text-center -mt-1.5">
                            {terrain_area} m<span className="align-super text-xs">2</span>
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
                { garage ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/garage.png")} alt="Garage(s)" />
                        <div className="text-center mt-0">
                            garage
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
                { parking ? (
                    <div className="flex flex-col justify-center">
                        <img className="w-24 h-24 bg-primary self-center" src={require("../../media/images/specs/ext_park.png")} alt="Place(s) de parking extérieure(s)" />
                        <div className="text-center mt-0">
                            parking
                        </div>
                    </div>
                )
                :
                (
                    ""
                )}
            </div>
        </div>
    );
}

export default PropertyPictograms;
