import {useContext, useState} from "react";
import {copySellForm, SellFormContext} from "./SellForm";

function RandomSpecs() {
    const { sellForm, setSellForm } = useContext(SellFormContext);

    const [garage,setGarage] = useState(sellForm.garage);
    const [parkingSpot,setParkingSpot] = useState(sellForm.parkingSpot);
    const [bathrooms,setBathrooms] = useState(sellForm.bathrooms);

    function updateSellForm(field, value){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm[field] = value;

        setSellForm(tempSellForm);
    }

    function handleGarage(e){
        setGarage(e.target.checked);

        updateSellForm('garage', e.target.checked)
    }

    function handleParkingSpot(e){
        setParkingSpot(e.target.checked);

        updateSellForm('parkingSpot', e.target.checked)
    }

    function handleBathrooms(e){
        setBathrooms(e.target.value);

        updateSellForm('bathrooms', e.target.value);
    }

    return (
        <div>
            <div className="grid md:grid-cols-2 gap-x-6">
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="livingArea" className="block font-medium p-1">Nombre de salles de bain *</label>
                        <input
                            className="border border-gray-300 rounded-lg focus:ring-primary focus:border-primary p-1 w-16"
                            type="number" min="1" max="5" value={bathrooms} onChange={handleBathrooms} />
                    </div>
                    <input id="livingArea" type="range" min="1" max="5" value={bathrooms} onChange={handleBathrooms} step="1"
                           className="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-light hover:accent-primary" />

                </div>
                <div></div>
                <div>
                    <div className="block font-medium p-1 mb-2">Garage *</div>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={garage} className="sr-only peer" onChange={handleGarage} />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-light
                                        rounded-full peer peer-checked:after:translate-x-full
                                        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                        after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                                        peer-checked:bg-primary-light"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900">{garage ? "Oui" : "Non"}</span>
                    </label>
                </div>
                <div>
                    <div className="block font-medium p-1 mb-2">Place de parking *</div>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value={parkingSpot} className="sr-only peer" onChange={handleParkingSpot} />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-light
                                        rounded-full peer peer-checked:after:translate-x-full
                                        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                        after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                                        peer-checked:bg-primary-light"></div>
                        <span className="ml-3 text-sm font-medium text-gray-900">{parkingSpot ? "Oui" : "Non"}</span>
                    </label>
                </div>
            </div>
        </div>
    );
}

export default RandomSpecs;
