import team from "../data/team.json";
import TeamCard from "../components/Team/TeamCard";

function Team() {
    return (
        <div className="pt-28 md:pt-52 pb-10 md:min-h-screen">
            <div className="container mx-auto w-3/4">
                <div className="text-6xl text-primary px-4 mb-8 text-center">
                    <h1>
                        Notre équipe
                    </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-6">
                    {team.people.map((person, index) =>
                        <TeamCard key={index} person={person} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Team;
