import {useContext, useState} from "react";
import {copySellForm, SellFormContext} from "./SellForm";

function SellFormType() {
    const appartement = {
        id: "Appartement",
        subTypeList: [
            "Appartement",
            "Dernier étage",
            "Duplex",
            "Appartement mansardé",
            "Appartement en terrasse",
            "Studio"
        ]
    };

    const house = {
        id: "Maison",
        subTypeList: [
            "Maison individuelle",
            "Maison jumelée",
            "Maison - mitoyenne en angle",
            "Maison - mitoyenne"
        ]
    };


    const { sellForm, setSellForm } = useContext(SellFormContext);
    const [type, setType] = useState(sellForm.type === house.id ? house : appartement );

    function updateType(newType){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm.type = newType.id;
        tempSellForm.subType = newType.subTypeList[0];

        setSellForm(tempSellForm);
    }

    function updateSubType(newType, newSubType){
        let tempSellForm = copySellForm(sellForm);

        tempSellForm.type = newType;
        tempSellForm.subType = newSubType;

        setSellForm(tempSellForm);
    }

    function handleTypeChange(id){
        if(id === appartement.id){
            setType(appartement);
            updateType(appartement);
        }
        else{
            setType(house);
            updateType(house);
        }
    }

    function handleSubTypeChange(value){
        value = JSON.parse(value);
        updateSubType(value.type,value.subType);
    }

    return (
        <div>

            <h3 className="block font-medium p-1 mb-2">
                Type *
            </h3>
            <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 mb-4">
                <li>
                        <label onClick={() => handleTypeChange(appartement.id)}
                               className={"inline-flex items-center justify-between w-full p-3 md:p-5 border " +
                                   "rounded-lg cursor-pointer " +
                                   (type.id === appartement.id ?
                                       "border-primary text-primary bg-primary-transparent" :
                                       "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                            <div className="block">
                                <div className="w-full text-lg font-semibold">Appartement</div>
                            </div>
                        </label>
                </li>
                <li>
                    <label onClick={() => handleTypeChange(house.id)}
                           className={"inline-flex items-center justify-between w-full p-3 md:p-5 border " +
                                       "rounded-lg cursor-pointer " +
                                       (type.id === house.id ?
                                           "border-primary text-primary bg-primary-transparent" :
                                           "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                        <div className="block">
                            <div className="w-full text-lg font-semibold">Maison</div>
                        </div>
                    </label>
                </li>
            </ul>

            <h3 className="block font-medium p-1 mb-2">
                Sous-type *
            </h3>
            <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-4">
                {type.subTypeList.map((subType, index) => (
                <li key={index}>
                    <label onClick={() => handleSubTypeChange(JSON.stringify({type: type.id, subType: subType}))}
                           className={"inline-flex items-center justify-between w-full h-full md:h-fit p-3 md:p-5 border " +
                               "rounded-lg cursor-pointer " +
                               (sellForm.subType === subType ?
                                   "border-primary text-primary bg-primary-transparent" :
                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                        <div className="block">
                            <div className="w-full text-lg font-semibold">{subType}</div>
                        </div>
                    </label>
                </li>
                ))}
            </ul>
        </div>
    );
}

export default SellFormType;
