import React, {useEffect, useState} from "react";
import "./Carousel.css";
import CarouselMobile from "./CarouselMobile";
import CarouselDesktop from "./CarouselDesktop";

function Carousel(props) {
    const images = props.images;
    const details = props.details

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    if (isMobile){
        return(
            <CarouselMobile images={images} details={details} />
        );
    }

    return (
        <CarouselDesktop images={images} details={details} />
    );
}

export default Carousel;
