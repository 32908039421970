import React, {useContext, useState} from "react";
import {API_ADDRESS} from "../../App";
import axios from "axios";
import {AppContext} from "../../App";

export const satisfactionFormDefault = {
    type: "",
    globalSatisfaction:"",
    communicationWithAgent: "",
    agentSkills: "",
    marketingSupportsSatisfaction: "",
    wouldYouRecommend: true,
    description:"",
    emailAdress:"",
    name:"",
    lastName:"",
    phoneNumber:""
};

const types = ["Immeuble", "Appartement", "Maison", "Terrain"];
const globalSatisfactions = ["Très satisfait", "Satisfait", "Neutre", "Insatisfait", "Très insatisfait"];
const communicationWithAgents = ["Très bonne", "Bonne", "Moyenne", "Mauvaise", "Très mauvaise"];
const agentSkills = ["Très compétent", "Compétent", "Moyennement compétent", "Peu compétent", "Pas du tout compétent"];
const marketingSupportsSatisfactions = ["Très bonne", "Bonne", "Moyenne", "Mauvaise", "Très mauvaise"];

function SatisfactionForm() {
    const {setToastIsOpen, setToastMessage, setToastType} = useContext(AppContext);
    const [satisfactionForm, setSatisfactionForm] = useState(satisfactionFormDefault);

    function copyForm() {
        return JSON.parse(JSON.stringify(satisfactionForm));
    }

    function validateForm(){
        return satisfactionForm.type !== satisfactionFormDefault.type &&
            satisfactionForm.globalSatisfaction !== satisfactionFormDefault.globalSatisfaction &&
            satisfactionForm.communicationWithAgent !== satisfactionFormDefault.communicationWithAgent &&
            satisfactionForm.agentSkills !== satisfactionFormDefault.agentSkills &&
            satisfactionForm.marketingSupportsSatisfaction !== satisfactionFormDefault.marketingSupportsSatisfaction &&
            satisfactionForm.description !== satisfactionFormDefault.description;
    }

    function sendForm() {
        if(validateForm()) {
            axios.post(API_ADDRESS + `/contact/satisfaction`, satisfactionForm)
                .then(res => {
                    if (res.data.sent) {
                        setToastType("info");
                        setToastMessage("Votre avis a été envoyé!");
                        setToastIsOpen(true);

                        window.location.replace('/');
                    }
                    else {
                        setToastType("warning");
                        setToastMessage("Veuillez renseigner tous les champs contenant un astérisque(*).");
                        setToastIsOpen(true);
                    }
                });
        }
        else{
            setToastType("warning");
            setToastMessage("Veuillez renseigner tous les champs contenant un astérisque(*).");
            setToastIsOpen(true);
        }
    }

    function handleTypeChange(type) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.type = type;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleOtherTypeChange(e) {
        handleTypeChange(e.target.value);
    }

    function handleGlobalSatisfactionChange(globalSatisfaction) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.globalSatisfaction = globalSatisfaction;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleCommunicationWithAgentChange(communicationWithAgent) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.communicationWithAgent = communicationWithAgent;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleAgentSkillsChange(agentSkills) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.agentSkills = agentSkills;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleMarketingSupportsSatisfactionChange(marketingSupportsSatisfaction) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.marketingSupportsSatisfaction = marketingSupportsSatisfaction;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleWouldYouRecommend(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.wouldYouRecommend = e.target.checked;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleDescriptionChange(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.description = e.target.value;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleNewRow(e) {
        if (e.code === "Enter") {
            document.getElementById("description").rows = document.getElementById("description").rows + 1;
        }
    }

    function handleLastNameChange(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.lastName = e.target.value;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleNameChange(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.name = e.target.value;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handleEmailAddressChange(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.emailAdress = e.target.value;

        setSatisfactionForm(tempSatisfactionForm);
    }

    function handlePhoneNumberChange(e) {
        let tempSatisfactionForm = copyForm();

        tempSatisfactionForm.phoneNumber = e.target.value;

        setSatisfactionForm(tempSatisfactionForm);
    }

    return (
        <div className="pt-28 md:pt-52 pb-10">
            <div className="container mx-auto w-4/5">
                <div className="flex flex-col justify-between">
                    <div className="py-10 px-4">
                        <h3 className="block font-medium p-1 mb-2">
                            1. Type de bien vendu *
                        </h3>
                        <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-4">
                            {types.map((type, index) => (
                                <li key={index}>
                                    <label onClick={() => handleTypeChange(type)}
                                           className={"inline-flex items-center justify-between w-full h-full md:h-fit p-3 md:p-5 border " +
                                               "rounded-lg cursor-pointer " +
                                               (satisfactionForm.type === type ?
                                                   "border-primary text-primary bg-primary-transparent" :
                                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{type}</div>
                                        </div>
                                    </label>
                                </li>
                            ))}
                            <li>
                                <label
                                       className={"inline-flex items-center justify-between w-full h-full md:h-fit p-3 md:p-5 border " +
                                           "rounded-lg cursor-pointer " +
                                           (!types.includes(satisfactionForm.type) && satisfactionForm.type !== satisfactionFormDefault.type ?
                                               "border-primary text-primary bg-primary-transparent" :
                                               "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                    <div className="block">
                                        <div className="w-full text-lg font-semibold">
                                            <div className="w-full text-lg font-semibold">Autre</div>
                                            <input type="text" name="type" id="type" onChange={handleOtherTypeChange} value={!types.includes(satisfactionForm.type) ? satisfactionForm.type : ""}
                                                   className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                                   placeholder=" " />
                                        </div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            2. Satisfaction globale *
                        </h3>
                        <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-5">
                            {globalSatisfactions.map((globalSatisfaction, index) => (
                                <li key={index}>
                                    <label onClick={() => handleGlobalSatisfactionChange(globalSatisfaction)}
                                           className={"inline-flex items-center justify-between w-full h-full md:h-fit p-3 md:p-5 border " +
                                               "rounded-lg cursor-pointer " +
                                               (satisfactionForm.globalSatisfaction === globalSatisfaction ?
                                                   "border-primary text-primary bg-primary-transparent" :
                                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{globalSatisfaction}</div>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            3. Communication avec l’agent immobilier *
                        </h3>
                        <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-5">
                            {communicationWithAgents.map((communicationWithAgent, index) => (
                                <li key={index}>
                                    <label onClick={() => handleCommunicationWithAgentChange(communicationWithAgent)}
                                           className={"inline-flex items-center justify-between w-full h-full md:h-fit p-3 md:p-5 border " +
                                               "rounded-lg cursor-pointer " +
                                               (satisfactionForm.communicationWithAgent === communicationWithAgent ?
                                                   "border-primary text-primary bg-primary-transparent" :
                                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{communicationWithAgent}</div>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            4. Compétence de l’agent immobilier *
                        </h3>
                        <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-5">
                            {agentSkills.map((agentSkill, index) => (
                                <li key={index}>
                                    <label onClick={() => handleAgentSkillsChange(agentSkill)}
                                           className={"inline-flex items-center justify-between w-full h-full md:h-full p-3 md:p-5 border " +
                                               "rounded-lg cursor-pointer " +
                                               (satisfactionForm.agentSkills === agentSkill ?
                                                   "border-primary text-primary bg-primary-transparent" :
                                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{agentSkill}</div>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            5. Qualité des supports marketing (photos, annonces, etc.) *
                        </h3>
                        <ul className="grid w-full gap-2 md:gap-6 grid-cols-2 md:grid-cols-5">
                            {marketingSupportsSatisfactions.map((marketingSupportsSatisfaction, index) => (
                                <li key={index}>
                                    <label onClick={() => handleMarketingSupportsSatisfactionChange(marketingSupportsSatisfaction)}
                                           className={"inline-flex items-center justify-between w-full h-full md:h-full p-3 md:p-5 border " +
                                               "rounded-lg cursor-pointer " +
                                               (satisfactionForm.marketingSupportsSatisfaction === marketingSupportsSatisfaction ?
                                                   "border-primary text-primary bg-primary-transparent" :
                                                   "text-gray-500 border-gray-200 hover:text-gray-600 hover:bg-gray-100 bg-white")}>
                                        <div className="block">
                                            <div className="w-full text-lg font-semibold">{marketingSupportsSatisfaction}</div>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            6. Recommanderiez-vous notre agence ? *
                        </h3>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" checked={satisfactionForm.wouldYouRecommend} value={satisfactionForm.wouldYouRecommend} className="sr-only peer" onChange={handleWouldYouRecommend} />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-light
                                        rounded-full peer peer-checked:after:translate-x-full
                                        peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                        after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                                        peer-checked:bg-primary-light"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">{satisfactionForm.wouldYouRecommend ? "Oui" : "Non"}</span>
                        </label>
                        <h3 className="block font-medium p-1 mb-2 mt-6">
                            7. Décrivez en quelques lignes votre expérience avec Foti Immobilier *
                        </h3>
                        <textarea onKeyDown={handleNewRow}
                                  name="description" id="description" rows="1" required onChange={handleDescriptionChange} value={satisfactionForm.description}
                                  className="block pb-2.5 pt-4 w-full text-sm text-primary bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                  placeholder=" "
                        ></textarea>
                        <h3 className="block font-medium mb-4 mt-6">
                            Vos données
                        </h3>
                        <div className="grid grid-cols-2 gap-x-6">
                            <div className="relative z-0 w-full mb-6 group">
                                <input type="text" name="last_name" id="last_name" onChange={handleLastNameChange}
                                       className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                       placeholder=" " required/>
                                <label htmlFor="last_name"
                                       className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Nom
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input type="text" name="name" id="name" onChange={handleNameChange}
                                       className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                       placeholder=" " required/>
                                <label htmlFor="name"
                                       className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Prénom
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input type="email" name="email" id="email" onChange={handleEmailAddressChange} value={satisfactionForm.emailAdress}
                                       className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                       placeholder=" " required/>
                                <label htmlFor="email"
                                       className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Addresse e-mail
                                </label>
                            </div>
                            <div className="relative z-0 w-full mb-6 group">
                                <input type="text" name="phone" id="phone" onChange={handlePhoneNumberChange} value={satisfactionForm.phoneNumber}
                                       className="block py-2.5 px-0 w-full text-sm text-primary bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-light peer"
                                       placeholder=" "/>
                                <label htmlFor="phone"
                                       className="peer-focus:font-medium absolute text-sm text-primary duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Téléphone
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button onClick={sendForm}
                                className="bg-primary hover:bg-primary-light text-white rounded px-2 py-1">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SatisfactionForm;
