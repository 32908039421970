import React, {useEffect, useState} from "react";
import {API_ADDRESS} from "../App";

function Promotions() {
    const [promotions, setPromotions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            fetch(API_ADDRESS + '/promotions')
                .then(res => res.json())
                .then(json => {
                    setPromotions(json.data);
                    setIsLoading(false);
                });
        };

        fetchData();
    }, []);


    if (isLoading){
        return <></>;
    }

    return (
        <div>
            <div className="flex justify-center mt-10">
                <div className="py-10 pt-32 md:pt-52 min-h-screen w-5/6 md:w-1/2">
                    {promotions.map((promotion, index) => (
                        <div key={index} className="mb-6">
                            <a href={"/promotion/"+promotion.ref} className="flex flex-col items-center bg-white md:flex-row hover:bg-gray-100">
                                <img className="object-cover w-full h-auto md:h-auto md:w-96" src={promotion.attachments[0].url} alt="" />
                                <div className="flex flex-col justify-between p-4 leading-normal">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{promotion.title}</h5>
                                    <p className="mb-3 font-normal text-gray-700">{promotion.subtitle}</p>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Promotions;
